import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import QuestionModal from "../../../components/question-modal"
import ToolBar from "../../../components/tool-bar"
import { Vector3 } from "three"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"

export default class TheAltarOfTheI extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/the-altar-of-the-i/panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-4985.02, 372.41, -6.63)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/reception/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-4717.16, 1583.4, 457.76)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Spots
    const prayerQuestion = this.createQuestionInfoSpot(
      "I would a prayer for a happy birthday",
      new Vector3(4025.23, 2939.31, -372.96)
    )

    const reflectiveQuestion = this.createQuestionInfoSpot(
      "The birthday is a reflective day",
      new Vector3(-3989.19, -208.11, 2994.97)
    )

    const believeQuestion = this.createQuestionInfoSpot(
      "I believe in birthdays",
      new Vector3(2344.09, -4413.04, 16.24)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)

    mainPanorama.add(prayerQuestion)
    mainPanorama.add(reflectiveQuestion)
    mainPanorama.add(believeQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(-4985.02, 372.41, -6.63), viewer)

    viewer.add(mainPanorama)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="The Altar Of The I" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="The Altar Of The I"
        >
          <p>If we take an introspective moment to reflect.</p>
          <p>
            What do I actually believe in? In the time and space wherein I grew
            up and now live, we mainly celebrate the ‘I’. Besides ourselves,
            also lots of other ‘I’s’. Potentially multiples, every day. We are
            celebrating the existence of the individual. The climax of norms and
            values being interwoven at the birthday party. And every now and
            then taking a deep breath, reflecting on our past or imagining our
            future self.
          </p>
          <p>
            On the rug of contemporary mentality, I do a prayer for a happy
            birthday.
          </p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
